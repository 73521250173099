import type { Database } from '@/types/database.types';

export function useConversations(limit = 6) {
  const supabase = useSupabaseClient<Database>();

  const { data: conversations } = useAsyncData('conversations', async () => {
    const { data } = await supabase
      .from('conversations')
      .select('*')
      .limit(limit)
      .order('created_at', { ascending: false });

    return data;
  });

  return conversations;
}
