import mitt from 'mitt';

type ApplicationEvents = {
  'chat:new': undefined;
  'session:expired': undefined;
};

const emitter = mitt<ApplicationEvents>();

export const useEvent = emitter.emit;
export const useListen = emitter.on;
