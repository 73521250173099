import type { Database } from '@/types/database.types';

export function useUserLimit() {
  const supabase = useSupabaseClient<Database>();
  const user = useSupabaseUser();

  const { status, data: limit } = useAsyncData('dailylimit', async () => {
    const { data } = await supabase.rpc('get_user_daily_limit', {
      p_user_id: user?.value?.id ?? '',
    });
    return data;
  });

  return { status, limit };
}
